import MainCard from 'ui-component/cards/MainCard';
import { Button, Input } from '@mui/material';
import react from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authenticated } from 'utils/checkerfunction';
import styled from 'styled-components';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useParams } from 'react-router';

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
`;

const InvoicePage = () => {
    // Authenticated();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = react.useState();
    const [open, setOpen] = react.useState(false);

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleAlert = () => {
        setOpen(true);
    };

    // get id from params
    const { id } = useParams();
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('name');
    const [url, setUrl] = react.useState('');

    // useEffect api to call a api via axios
    react.useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASEURL}admin/user/getInvoice?userId=${id}`)
            .then((res) => {
                console.log(res.data);
                if (res.data.result === 'success') {
                    console.log('the url is ', res.data.url);
                    setUrl(res.data.url);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('userId', data.user_id);
        formData.append('files', selectedImage);

        const plan = new URLSearchParams(search).get('reportType');

        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/user/uploadInvoice`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                if (response.data.result === 'success') {
                    handleAlert();
                    setTimeout(() => {
                        navigate('/user/subscribed', '_self');
                    }, 2200);
                } else {
                    console.log('Something went wrong');
                }
            });
    };

    console.log(errors);
    const Alert = react.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    return (
        <div>
            <Button onClick={() => navigate('/user/newTest', '_self')} style={{ color: 'darkgrey' }}>
                ← Back
            </Button>
            <br />
            <Snackbar open={open} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Invoice Uploaded Successfully
                </Alert> 
            </Snackbar>
            
            <MainCard>
                <h1>Upload Invoice </h1>
                <br />
                <br />
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h3>Customer Name: {capitalize(name)}</h3>
                    <Input name="id" value={id} {...register('user_id')} readonly />
                    <br />
                    <br />
                    <br />
                    <div className="form-group">
                        <Input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={imageChange} />
                    </div>
                    <br />
                    <br />
                    <div>
                        {selectedImage ? (
                            <Button type="submit" variant="outlined">
                                Upload
                            </Button>
                        ) : (
                            <p style={{ color: 'red' }}>Please Select File To Upload.</p>
                        )}
                        {url && (
                            <Button
                                variant="contained"
                                style={{ marginLeft: '14px', color: '#ffffff' }}
                                onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
                            >
                                View Existing Invoice
                            </Button>
                        )}
                    </div>
                </Form>
            </MainCard>
            {console.log(selectedImage)}
        </div>
    );
};

export default InvoicePage;
