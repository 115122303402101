import react, { useState, useEffect, useCallback } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate, useParams } from 'react-router';
import { Button, FormLabel, TextareaAutosize, Select, Tooltip, MenuItem, Modal, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Authenticated } from 'utils/checkerfunction';
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import styled from 'styled-components';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import "./style.css";
import { Box, padding } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import _ from 'lodash';
import { GridCloseIcon } from '@mui/x-data-grid';



const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
`;

const UpdateCustomerField = () => {
    const [rows, setRows] = useState([]);
    const [message, setMessage] = useState("");
    const [trigger, setTrigger] = useState(0);
    const [showAddStatus, setShowAddStatus] = useState(false);
    const [OpenConsulationHistory, setOpenConsulationHistory] = useState(false);
    const [ConsulationHistory, setConsulationHistory] = useState([]);
    const addRow = () => {
        // const newRow = { id: rows.length + 1, dna_kit_dispatched: '', dna_report: '', dna_kit_received: '', dna_status: `${rows.length + 0}` };
        // setRows([...rows, newRow]);
        setOpenModal(true)
    };

    const ModalTableHeading = styled.div`
        font-size :  1rem;
        font-weight: 700;
    `;

    const deleteRow = async (rowId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASEURL}admin/customer/dna_status`, { data: { "id": rowId, "user_id": id } })
            console.log(response)
            if (response.data.result === "success") {
                setMessage(response.data.message)
                fetchData()
                handleSuccessAlert();
                setTrigger(trigger + 1)
            }
        } catch (error) {
            setMessage(error.response.data.message)
            handleNetworkErrorAlert()
        }

    };

    const [userObj, setUserObj] = react.useState({});
    const [Payment, setPayment] = react.useState([]);
    const [Subscription, setSubscription] = react.useState([]);
    const [data, setData] = useState([]);
    const [dnaObject, setDnaObject] = useState(
        {
            "dna_kit_dispatched": false,
            "dna_report": '',
            "dna_kit_received": false,
            "dna_status": ''
        }
    )
    const [openModal, setOpenModal] = react.useState(false);

    const closeModal = () => setOpenModal(false);


    const [otherStatus, setOtherStatus] = useState(
        {
            "blood_report_status": "",
            "invoice_id": "",
            "doctor_counselling": "",
            "nutritionist_counselling": "",
            "diet_plan": "",
            "doctor_prescription": "",
            "naf": "",
            "barcode": null
        }
    );

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        px: 5,
        pt: 5,
        pb: 2,
        borderRadius: "10px"
    };



    const handleDNAChange = (event, index, keyName) => {
        const id = rows[index].id;
        const newDnaTestObj = { ...dnaTestObj };
        newDnaTestObj.id = id;
        const newRows = [...rows];
        if (keyName === "kit_status_timestamp" || keyName === "dna_sample_timestamp") {
            const date = event.toDate()
            newRows[index][keyName] = date.toISOString();
            newDnaTestObj[keyName] = date.toISOString();
        } else {
            newRows[index][keyName] = event.target.value;
            newDnaTestObj[keyName] = event.target.value;
        }
        setRows(newRows);
        setDnaTestObj(newDnaTestObj);
        console.log(newRows, "newRows")
        console.log(newDnaTestObj, "newDnaTestObj")
    };





    // Authenticated();
    const [open, setOpen] = react.useState(false);
    const [open1, setOpen1] = react.useState(false);
    const [open2, setOpen2] = react.useState(false);


    // const dnaTestKit = ["Dispatched", "Not Dispatched"];

    const { formState: { errors } } = useForm();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
    };

    const handleSuccessAlert = () => {
        setOpen(true);
    };

    const handleFailureAlert = () => {
        setOpen1(true);
    };
    const handleNetworkErrorAlert = (errorMsg) => {
        setOpen2(true);
    };


    const handleSetUserObjValueUpdate = (newValue) => {
        setUserObj(newValue);
    };

    // get id from params
    const { id } = useParams();
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('name');
    const [url, setUrl] = react.useState('');
    const [dnaTestObj, setDnaTestObj] = react.useState({});

    // useEffect api to call a api via axios
    useEffect(() => {
        fetchData()
    }, []);


    const fetchData = () => {
        setShowAddStatus(false)
        axios
            .get(`${process.env.REACT_APP_BASEURL}admin/customer?user_id=${id}`)
            .then((res) => {
                console.log(res.data.data[0]);
                if (res.data.result === 'success') {
                    setUserObj(res.data.data[0])
                    const newRows = [];
                    res.data.data[0].dna_test.forEach((element, index) => {
                        newRows.push({
                            "id": element.id,
                            "user_id": element.user_id,
                            "test_kit_status": element.test_kit_status,
                            "kit_status_timestamp": element.test_kit_status === "Dispatched" ? element.kit_status_timestamp : "",
                            "dna_report": element.dna_report,
                            "dna_sample": element.dna_sample,
                            "dna_sample_timestamp": element.dna_sample === "Collected" ? element.dna_sample_timestamp : "",
                            "dna_status": element.dna_status,
                            "barcode" : element.barcode,
                            "is_enabled": element.is_enabled,
                            "created_at": element.created_at,
                        })
                    });
                    setRows(newRows)
                    setUrl(res.data.url);
                    setPayment(res.data.data[0].payments)
                    setSubscription(res.data.data[0].subscriptions)
                    setConsulationHistory(res.data.data[0].consultation_history)
                    if(res.data.data[0].dna_test[res.data.data[0].dna_test.length - 1].dna_status === "Failed"){
                        setShowAddStatus(true)
                    }
                    // setDnaObject(userObj.dna_test);
                    setOtherStatus(res.data.data[0].other_status);
                    setTrigger(trigger + 1)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const saveStatus = () => {
        const requestBody = {
            user_id: id,
            dna_test: dnaTestObj
        }
        setDnaTestObj({});
        axios.post(`${process.env.REACT_APP_BASEURL}admin/customer/update_status_v2`, requestBody)
        // axios.post(`http://localhost:4002/admin/customer/update_status_v2`, requestBody)
            .then((response) => {
                if (response.data.result === 'success') {
                    setMessage(response.data.message);
                    handleSuccessAlert();
                    fetchData();
                } else {
                    handleFailureAlert(response.data.message);
                    fetchData();
                }
            })
            .catch((error) => {
                fetchData();
                setMessage(error.response.data.message)
                handleNetworkErrorAlert();
                console.error(error);
            });
    }

    const onSubmit = (updatedRows, newDnaObject) => {

        const requestBody = {
            user_id: id,
            dna_test: {
                "dna_kit_dispatched": newDnaObject.test_kit_status ? newDnaObject.test_kit_status === 'Dispatched' : undefined,
                "dna_report": newDnaObject.dna_report,
                "dna_kit_received": newDnaObject.dna_sample ? newDnaObject.dna_sample === 'Collected' : undefined,
                "dna_status": newDnaObject.dna_status,
                "kit_status_timestamp": newDnaObject.kit_status_timestamp,
                "dna_sample_timestamp": newDnaObject.dna_sample_timestamp
            }
        }

        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/customer/update_status`, requestBody)
            .then((response) => {
                if (response.data.result === 'success') {
                    setRows(updatedRows)
                    setMessage(response.data.message);
                    handleSuccessAlert();
                } else {
                    handleFailureAlert(response.data.message);
                }
            })
            .catch((error) => {
                setMessage(error.response.data.message)
                handleNetworkErrorAlert();
                console.error(error);
            });
    };


    const handleSure = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}admin/customer/add_new_status`, { "user_id": id });
            setMessage(response.data.message);
            handleSuccessAlert()
            fetchData();
            closeModal();
        } catch (error) {
            setMessage(error.response.data.message)
            handleNetworkErrorAlert()
            closeModal();
        }
    }

    const handleDateTime = (e) => {
        console.log(e.toISOString())
    }

    // const onSubmit = () => {
    //     const requestBody = {
    //       user_id: id,
    //       dna_test: {
    //         "dna_kit_dispatched": dnaObject.dna_kit_dispatched === 'true' ? true : false,
    //         "dna_report": dnaObject.dna_report,
    //         "dna_kit_received": dnaObject.dna_kit_received === 'true' ? true : false,
    //         "dna_status": dnaObject.dna_status
    //       }
    //     };

    //     axios
    //       .post(`${process.env.REACT_APP_BASEURL}admin/customer/update_status`, requestBody)
    //       .then((response) => {
    //         if (response.data.result === 'success') {
    //           handleAlert();
    //           setTimeout(() => {
    //             window.open('/customer-field', '_self');
    //           }, 1000);
    //         } else {
    //           console.log('Something went wrong');
    //         }
    //       })
    //       .catch((error) => {
    //         handleAlert();
    //         console.error(error);
    //       });
    //   };

    console.log(errors);
    const navigate = useNavigate();
    const Alert = react.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    const handleFamilyMember = (id) => {
        navigate(`/update_customer_field/${id}`)
    }

    const formatDate = (dateString) => dayjs(dateString).format('DD-MM-YYYY');


    return (
        <>
            <div>
                <Snackbar open={open} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <Snackbar open={open1} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                        Customer Field Already Exist!
                    </Alert>
                </Snackbar>

                <Snackbar open={open2} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>

                <MainCard style={{ padding: "8px 10px" }}>
                    <MainCard style={{ border: "2px solid #adadad", boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}>
                        <div className="d-flex">
                            <div className='UserInfo'>
                                <ArrowCircleLeftIcon
                                    onClick={() => navigate('/customer-field', '_self')}
                                    sx={{ cursor: 'pointer', color: '#02a9f4' }}
                                />
                                <span className='flexSpan'>
                                    {userObj?.about?.name}{`'`}s Information
                                </span>
                            </div>

                            {/* <div className='BtnUpdate'>
                                <Button onClick={() => { onSubmit() }}>Update</Button>
                            </div> */}
                        </div>
                    </MainCard>
                    <br />

                    <MainCard style={{ border: "2px solid #adadad", padding: "8px 10px" }}>
                        <h3>About</h3>
                        <hr style={{ marginBottom: "24px" }} />
                        <div className="d-flex gap-40 flex-wrap">
                            <div className="text-field flex-0-9">
                                <FormLabel htmlFor='name' className="form-label">Name</FormLabel>
                                <TextField
                                    type="text"
                                    id="name"
                                    name="name"
                                    disabled={!data.available}
                                    value={userObj?.about?.name}
                                    variant="outlined"
                                    placeholder="Name"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }}
                                />
                            </div>
                            <div className="text-field flex-1-3">
                                <FormLabel htmlFor='email' className="form-label">Email</FormLabel>
                                <TextField
                                    type="email"
                                    id="email_address"
                                    name="email_address"
                                    value={userObj?.about?.email}
                                    disabled={!data.available}
                                    variant="outlined"
                                    placeholder="Email"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }}
                                />
                            </div>
                            <div className="text-field flex-0-9">
                                <FormLabel htmlFor='phone' className="form-label">Phone</FormLabel>
                                <TextField
                                    type="tel"
                                    id="phone_number"
                                    name="phone_number"
                                    value={userObj?.about?.phone}
                                    disabled={!data.available}
                                    placeholder="Phone"
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                            <div className="text-field flex-0-9">
                                <FormLabel htmlFor='employee_id' className="form-label">Employee Id</FormLabel>
                                <TextField
                                    type="text"
                                    id="employee_id"
                                    name="employee_id"
                                    placeholder='Employee Id'
                                    value={userObj?.about?.employee_id}
                                    disabled={!data.available}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                        </div>
                        <br />

                        <h3>Address For Courier</h3>
                        <hr style={{ marginBottom: "24px" }} />

                        <div className="d-flex gap-40 flex-wrap">
                            <div className="text-field">
                                <FormLabel htmlFor='line1' className="form-label">Line 1</FormLabel>
                                <TextField
                                    type="text"
                                    id="line1"
                                    name="line1"
                                    disabled={!data.available}
                                    placeholder='Line 1'
                                    value={userObj?.address?.line1}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                            <div className="text-field">
                                <FormLabel htmlFor='line2' className="form-label">Line 2</FormLabel>
                                <TextField
                                    type="text"
                                    id="line2"
                                    name="line2"
                                    placeholder='Line 2'
                                    value={userObj?.address?.line2}
                                    disabled={!data.available}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }}
                                />
                            </div>
                            <div className="text-field">
                                <FormLabel htmlFor='city' className="form-label">City</FormLabel>
                                <TextField
                                    type="city"
                                    id="city"
                                    name="city"
                                    placeholder='City'
                                    value={userObj?.address?.city}
                                    disabled={!data.available}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                            <div className="text-field">
                                <FormLabel htmlFor='pincode' className="form-label">Pincode</FormLabel>
                                <TextField
                                    type="pincode"
                                    id="pin"
                                    name="pin"
                                    placeholder='Pincode'
                                    value={userObj?.address?.pin}
                                    disabled={!data.available}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                        </div>

                        <div className="d-flex gap-40 flex-wrap mt-20">
                            <div className="text-field flex-0-5">
                                <FormLabel htmlFor='landmark' className="form-label">Landmark</FormLabel>
                                <TextField
                                    type="text"
                                    id="landmark"
                                    name="landmark"
                                    placeholder='landmark'
                                    style={{ width: '100%' }}
                                    value={userObj?.address?.landmark}
                                    disabled={!data.available}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>

                            <div className="text-field flex-0-25">
                                <FormLabel htmlFor='state' className="form-label">State</FormLabel>
                                <TextField
                                    type="state"
                                    id="state"
                                    name="state"
                                    placeholder='State'
                                    value={userObj?.address?.state}
                                    disabled={!data.available}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                            <div className="text-field flex-0-25">
                                <FormLabel htmlFor='country' className="form-label">Country</FormLabel>
                                <TextField
                                    type="country"
                                    id="country"
                                    name="country"
                                    placeholder='Country'
                                    value={userObj?.address?.country}
                                    disabled={!data.available}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                        </div>

                        <br />
                        {
                            Payment.length > 0 &&
                            <>
                                <h3>Payment</h3>
                                <hr style={{ marginBottom: "24px" }} />
                                {Payment.map((Payment) => (                        
                                <div className="d-flex gap-40 flex-wrap" style={{marginBottom: 35}}>

                                <div className="text-field">
                                    <FormLabel htmlFor='payment_id' className="form-label">Payment Id</FormLabel>
                                    <TextField
                                        type="text"
                                        id="payment_id"
                                        name="payment_id"
                                        placeholder='Payment Id'
                                        value={Payment?.payment_id}
                                        disabled={!data.available}
                                        variant="outlined"
                                        InputProps={{
                                            sx: {
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "15px"
                                                },
                                            }
                                        }} />
                                </div>

                                <div className="text-field">
                                    <FormLabel htmlFor='payment_method' className="form-label">Payment Method</FormLabel>
                                    <TextField
                                        type="text"
                                        id="payment_method"
                                        name="payment_method"
                                        placeholder='Payment Method'
                                        value={Payment?.payment_method}
                                        disabled={!data.available}
                                        variant="outlined"
                                        InputProps={{
                                            sx: {
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "15px"
                                                },
                                            }
                                        }} />
                                </div>
                                <div className="text-field">
                                    <FormLabel htmlFor='amount_paid' className="form-label">Amount Paid</FormLabel>
                                    <TextField
                                        type="text"
                                        id="amount_paid"
                                        name="amount_paid"
                                        placeholder='Amount Paid'
                                        value={Payment?.amount_paid ? Payment?.amount_paid + " Rs." : "No Status"}
                                        disabled={!data.available}
                                        variant="outlined"
                                        InputProps={{
                                            sx: {
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "15px"
                                                },
                                            }
                                        }} />
                                </div>
                                <div className="text-field">
                                    <FormLabel htmlFor='Subscription' className="form-label">Subscription</FormLabel>
                                    <TextField
                                        type="text"
                                        id="Subscription"
                                        name="Subscription"
                                        placeholder='Subscription'
                                        value={Payment?.subscription}
                                        disabled={!data.available}
                                        variant="outlined"
                                        InputProps={{
                                            sx: {
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "15px"
                                                },
                                            }
                                        }} />
                                </div>
                                </div>
                                ))}
                            </>
                        }
                        {
                            Subscription.length > 0 &&
                            <>
                                <h3>Subscription</h3>
                                <hr style={{ marginBottom: "24px" }} />
                                
                                {Subscription.map((Subscription) => (
                                <>
                                <div className="d-flex gap-40 flex-wrap">

                                    <div className="text-field">
                                        <FormLabel htmlFor='dna_report_uploaded_at' className="form-label">Plan Status</FormLabel>
                                        <TextField
                                            type="text"
                                            id="dna_report_uploaded_at"
                                            name="dna_report_uploaded_at"
                                            placeholder='DNA Report Uploaded Date'
                                            value={Subscription.plan_status}
                                            disabled={!data.available}
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>

                                    <div className="text-field">
                                        <FormLabel htmlFor='plan_name' className="form-label">Plan Name</FormLabel>
                                        <TextField
                                            type="text"
                                            id="plan_name"
                                            name="plan_name"
                                            placeholder='Plan Name'
                                            value={Subscription?.plan_name}
                                            disabled={!data.available}
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>

                                    <div className="text-field">
                                        <FormLabel htmlFor='plan_start' className="form-label">Plan Start Date</FormLabel>
                                        <TextField
                                            type="text"
                                            id="plan_start"
                                            name="plan_start"
                                            placeholder='Plan Start Date'
                                            value={dayjs(Subscription?.plan_start).format("DD/MM/YYYY")}
                                            disabled={!data.available}
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>

                                    <div className="text-field">
                                        <FormLabel htmlFor='plan_end' className="form-label">Plan End Date</FormLabel>
                                        <TextField
                                            type="text"
                                            id="plan_end"
                                            name="plan_end"
                                            placeholder='Plan End Date'
                                            value={dayjs(Subscription?.plan_end).format("DD/MM/YYYY")}
                                            disabled={!data.available}
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>
                                    <div className="text-field">
                                        <FormLabel htmlFor='plan_end' className="form-label">Plan Duration</FormLabel>
                                        <TextField
                                            type="text"
                                            id="plan_duration"
                                            name="plan_duration"
                                            placeholder='Plan Duration'
                                            value={Subscription?.plan_duration}
                                            disabled={!data.available}
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>
                                </div>
                                <br />
                                </>
                                ))}
                            </>
                        }

                        {
                            userObj?.add_on_subscription?.length > 0 &&
                            <>
                                <h3>Add On Subscription</h3>
                                <hr style={{ marginBottom: "24px" }} />
                                {userObj?.add_on_subscription.map((subscription, index) =>
                                    <>
                                        <div className="d-flex gap-40 flex-wrap" >
                                            <div className="text-field" style={{ flex: "1.5" }}>
                                                <FormLabel htmlFor='product_name' className="form-label">Product Name</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="product_name"
                                                    name="product_name"
                                                    placeholder='Product Name'
                                                    value={subscription.product_name}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>

                                            <div className="text-field" style={{ flex: "1.5" }}>
                                                <FormLabel htmlFor='type_of_service' className="form-label">Type of services</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="type_of_service"
                                                    name="type_of_service"
                                                    placeholder='Type of services'
                                                    value={subscription.type_of_service}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-40 flex-wrap" key={index} style={{ marginTop: "20px" }}>

                                            <div className="text-field" style={{ minWidth: '124px', flex: 0.2 }}>
                                                <FormLabel htmlFor='count_of_service' className="form-label">Count of Services</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="count_of_service"
                                                    name="count_of_service"
                                                    placeholder='Count of Services'
                                                    value={subscription.count_of_service}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>

                                            <div className="text-field">
                                                <FormLabel htmlFor='payment_id' className="form-label">Payment ID</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="payment_id"
                                                    name="payment_id"
                                                    placeholder='Payment ID'
                                                    value={subscription?.payment_id}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>
                                            <div className="text-field">
                                                <FormLabel htmlFor='payment_menthod' className="form-label">Payment Method</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="payment_method"
                                                    name="payment_method"
                                                    placeholder='Payment Method'
                                                    value={subscription?.payment_method}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>
                                            <div className="text-field">
                                                <FormLabel htmlFor='amount' className="form-label">Amount Paid</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="amount"
                                                    name="amount"
                                                    placeholder='Amount Paid'
                                                    value={subscription?.amount}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>
                                            <div className="text-field">
                                                <FormLabel htmlFor='date_of_payment' className="form-label">Date of Payment</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="date_of_payment"
                                                    name="date_of_payment"
                                                    placeholder='Date of Payment'
                                                    value={(subscription?.date_of_payment && subscription?.date_of_payment !== 'No Status') ? dayjs(subscription?.date_of_payment).format("DD/MM/YYYY") : subscription?.date_of_payment}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>
                                            <div className="text-field">
                                                <FormLabel htmlFor='is_expired' className="form-label">Plan Expired</FormLabel>
                                                <TextField
                                                    type="text"
                                                    id="is_expired"
                                                    name="is_expired"
                                                    placeholder='Plan Expired'
                                                    value={subscription?.is_expired}
                                                    disabled
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "15px"
                                                            },
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                        {index !== userObj?.add_on_subscription.length - 1 && <hr style={{ marginBottom: "24px", marginTop: "24px" }} />}
                                    </>
                                )}

                                <br />
                            </>
                        }


                        {userObj?.family_members?.length !== 0 && <><h3>Family Members</h3>
                            <hr style={{ marginBottom: "24px" }} /></>}
                        <div >
                            {userObj.family_members ? userObj.family_members.map((member, index) => (

                                <div className="d-flex gap-40 flex-wrap" key={member.id} style={{ marginTop: "20px" }}>
                                    <div className="text-field">
                                        <FormLabel htmlFor='name' className="form-label" style={{ marginBottom: "10px" }}>Name</FormLabel>
                                        <TextField
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder='Family Member Name'
                                            value={member.name}
                                            // disabled={!data.available}
                                            disabled
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>

                                    <div className="text-field">
                                        <FormLabel htmlFor='relationship' className="form-label" style={{ marginBottom: "10px" }}>Relationship</FormLabel>
                                        <TextField
                                            type="text"
                                            id="relationship"
                                            name="relationship"
                                            placeholder='Relationship'
                                            value={member.relationship}
                                            // disabled={!data.available}
                                            disabled
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>

                                    <div className="text-field">
                                        <FormLabel htmlFor='plan_name' className="form-label" style={{ marginBottom: "10px" }}>Plan Name</FormLabel>
                                        <TextField
                                            type="text"
                                            id="plan_name"
                                            name="plan_name"
                                            placeholder='No Plan Name'
                                            value={member?.subscription?.plan_name}
                                            // disabled={!data.available}
                                            disabled
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>

                                    <div className="text-field">
                                        <FormLabel htmlFor='plan_duration' className="form-label" style={{ marginBottom: "10px" }}>Plan Duration</FormLabel>
                                        <TextField
                                            type="text"
                                            id="plan_duration"
                                            name="plan_duration"
                                            placeholder='No Plan Duration'
                                            value={member.subscription.plan_duration}
                                            // disabled={!data.available}
                                            disabled
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }} />
                                    </div>

                                    <Button variant='contained' color='primary' sx={{ maxWidth: "100px", marginTop: "30px", color: "white" }} onClick={() => handleFamilyMember(member.id)}>View
                                    </Button>

                                </div>
                            )) : <></>
                            }
                        <br />
                        </div>

                        {userObj?.family_members &&
                            <div style={{ display: userObj?.family_members ? "none" : "block" }}>
                                <h3>Family Members</h3>
                                <hr style={{ marginBottom: "24px" }} />
                                <div className="displayFlexStart">
                                    <div>
                                        <FormLabel htmlFor='member_1' className="form-label">Member 1</FormLabel>
                                        <TextField
                                            type="text"
                                            id="member_1"
                                            name="member_1"
                                            placeholder='Member 1'
                                            value={userObj?.family_members}
                                            onChange={(event) => setUserObj(event.target.value)}
                                            variant="outlined"
                                            disabled={!data.available}
                                            InputProps={{
                                                sx: {
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "15px"
                                                    },
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        }

                        {userObj && userObj.dna_test_booked && (
                            <div
                                style={{ display: userObj && userObj.dna_test ? "block" : "none" }}
                            >
                                <div className="d-flex justify-space-between">
                                    <div>
                                        <h3>DNA Test Status</h3>
                                    </div>


                                </div>

                                <Modal open={openModal} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                    <Box sx={style}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
                                            <div>Are you sure? You want to add a row?</div>
                                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center", marginTop: "10px" }}>
                                                <Button color="error" variant="outlined" onClick={closeModal}>Cancel</Button>
                                                <Button variant="contained" onClick={() => handleSure()} style={{ background: "#269ee0", color: "#fff", padding: "0px 24px" }}>Sure</Button>
                                            </div>
                                        </div>
                                    </Box>
                                </Modal>

                                <hr style={{ marginBottom: "24px" }} />
                                {rows.length === 0 ? (
                                    <p>No data available</p>
                                ) : (
                                    rows.map((row, index) => (
                                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                            <div className="d-flex gap-40 flex-wrap" key={row.id} style={{ marginTop: "18px" }}>
                                                {/* <div className="text-field">
                                                    <FormLabel htmlFor='dna_test_kit' className="form-label">DNA Test Kit</FormLabel>
                                                    <Select
                                                        fullWidth
                                                        variant="outlined"
                                                        value={row.test_kit_status}
                                                        onChange={(event) => handleDNAChange(event, row.id)}
                                                        size="small"
                                                        disabled={!row.is_enabled}
                                                        name="test_kit_status"
                                                        style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                                                        inputProps={{ 'aria-label': 'Select an option' }}
                                                        sx={{
                                                            width: "100%", "& .MuiSelect-select": {
                                                                padding: "15px!important"
                                                            }
                                                        }}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="" disabled>Select an option</MenuItem>

                                                        <MenuItem value="Dispatched">Dispatched</MenuItem>
                                                        <MenuItem value="Not Dispatched">Not Dispatched</MenuItem>
                                                    </Select>
                                                </div> */}

                                                <div className="text-field">
                                                    <FormLabel htmlFor='kit_status_timestamp' className="form-label">Kit Dispatched Date </FormLabel>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker sx={{
                                                            borderRadius: "8px",
                                                            '& .MuiInputBase-root': {
                                                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                                                            },
                                                        }}
                                                            value={row.kit_status_timestamp ? dayjs(row.kit_status_timestamp) : null}
                                                            onChange={(event) => handleDNAChange(event, index, 'kit_status_timestamp')}
                                                            format="DD/MM/YYYY"
                                                            disabled={!row.is_enabled}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="text-field">
                                                    <FormLabel htmlFor='barcode' className="form-label">Barcode</FormLabel>
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        type="text"
                                                        id="barcode"
                                                        name='barcode'
                                                        disabled={!data.available}
                                                        placeholder='Barcode'
                                                        value={row.barcode}
                                                        variant="outlined" />
                                                </div>
                                                {/* 
                                                <div className="text-field">
                                                    <FormLabel htmlFor='dna_sample' className="form-label">Kit Pickup Status</FormLabel>
                                                    <Select
                                                        fullWidth
                                                        // value={dnaObject.dna_kit_received}
                                                        value={row.dna_sample}
                                                        onChange={(event) => handleDNAChange(event, row.id)}
                                                        variant="outlined"
                                                        disabled={!row.is_enabled}
                                                        // disabled={!data.available}
                                                        name="dna_sample"
                                                        size="small"
                                                        style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                                                        inputProps={{ 'aria-label': 'Select an option' }}
                                                        sx={{
                                                            width: "100%", "& .MuiSelect-select": {
                                                                padding: "15px!important"
                                                            }
                                                        }}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select an option
                                                        </MenuItem>
                                                        <MenuItem value="Collected">Collected</MenuItem>
                                                        <MenuItem value="Not Collected">Not Collected</MenuItem>
                                                    </Select>
                                                </div> */}

                                                <div className="text-field">
                                                    <FormLabel htmlFor='dna_sample_timestamp' className="form-label">Kit Received Date</FormLabel>

                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DatePicker sx={{
                                                            borderRadius: "8px", '& .MuiInputBase-root': {
                                                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                                                            }
                                                        }} value={row.dna_sample_timestamp ? dayjs(row.dna_sample_timestamp) : null}
                                                            onChange={(event) => handleDNAChange(event, index, 'dna_sample_timestamp')}
                                                            format="DD/MM/YYYY"
                                                            disabled={!row.is_enabled} />
                                                    </LocalizationProvider>
                                                </div>

                                                <div className="text-field">
                                                    <FormLabel htmlFor='dna_report' className="form-label">DNA Report</FormLabel>
                                                    <Select
                                                        fullWidth
                                                        // value={dnaObject.dna_report}
                                                        value={row.dna_report}
                                                        onChange={(event) => handleDNAChange(event, index, "dna_report")}
                                                        variant="outlined"
                                                        disabled={!row.is_enabled}
                                                        // disabled={!data.available}
                                                        size="small"
                                                        name="dna_report"
                                                        style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                                                        inputProps={{ 'aria-label': 'Select an option' }}
                                                        sx={{
                                                            width: "100%", "& .MuiSelect-select": {
                                                                padding: "15px!important"
                                                            }
                                                        }}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select an option
                                                        </MenuItem>
                                                        <MenuItem value="Loaded">Loaded</MenuItem>
                                                        <MenuItem value="Not Loaded">Not Loaded</MenuItem>
                                                    </Select>
                                                </div>
                                                <div className="text-field">
                                                    <FormLabel htmlFor='dna_test_status' className="form-label">DNA Test Status</FormLabel>
                                                    <Select
                                                        fullWidth
                                                        // value={dnaObject.dna_status}
                                                        value={row.dna_status}
                                                        onChange={(event) => handleDNAChange(event, index, 'dna_status')}
                                                        variant="outlined"
                                                        disabled={!row.is_enabled}
                                                        // disabled={!data.available}
                                                        size="small"
                                                        style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                                                        inputProps={{ 'aria-label': 'Select an option' }}
                                                        sx={{
                                                            width: "100%", "& .MuiSelect-select": {
                                                                padding: "15px!important"
                                                            }
                                                        }}
                                                        name="dna_status"
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select an option
                                                        </MenuItem>
                                                        <MenuItem value="Completed">Completed</MenuItem>
                                                        <MenuItem value="Failed">Failed</MenuItem>
                                                        <MenuItem value="Pending">Pending</MenuItem>
                                                    </Select>
                                                </div>




                                            </div>
                                            <div style={{ marginTop: "60px" }}>
                                                {
                                                    index > 0 ?
                                                        (<Tooltip title="Delete Field">
                                                            <DeleteIcon style={{ color: "#ff5500", cursor: "pointer" }}
                                                                onClick={() => deleteRow(row.id)}
                                                            />
                                                        </Tooltip>) : (
                                                            // eslint-disable-next-line react/self-closing-comp
                                                            <div style={{ width: "1.5rem" }} ></div>
                                                        )
                                                }
                                            </div>
                                        </div>

                                    ))
                                )}

                                <br />

                            </div>
                        )}
                        {userObj && userObj.dna_test_booked && (
                            <div className='BtnUpdate'>
                                {
                                    showAddStatus && (
                                        <Button onClick={addRow}>Add DNA Kit</Button>
                                    )
                                }
                                <Button onClick={saveStatus}
                                    disabled={(dnaTestObj.id || dnaTestObj.id === 0) ? false : true}
                                >Save Status</Button>
                            </div>
                        )
                        }
                        {
                            userObj?.counselling?.doctor.length === 0 && userObj?.counselling?.nutrigenetic_expert.length === 0 && userObj?.counselling?.nutritionist.length === 0 && userObj?.counselling?.emo_expert?.length === 0 && userObj?.counselling?.psychiatrist_expert?.length === 0 ? <></> :
                                <>
                                    <div style={{display: 'flex', alignItems:'center', margin: 0, justifyContent: 'start'}} className='BtnUpdate'>
                                        <h3>Counselling</h3>
                                        {ConsulationHistory && ConsulationHistory.length > 0 && (<div>
                                            <Button onClick={()=>{setOpenConsulationHistory(true)}}>Consultation History</Button>
                                        </div>)}
                                    </div>
                                    <hr style={{ marginBottom: "24px" }} />

                                    <Modal open={OpenConsulationHistory} onClose={()=>{setOpenConsulationHistory(false)}}>
                                        <Box 
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '1050px',
                                            height: '650px',
                                            bgcolor: 'background.paper',
                                            borderRadius: '10px',
                                            p: 4,
                                        }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 15 }}>
                                                <ModalTableHeading>Consultation History</ModalTableHeading>
                                                <GridCloseIcon sx={{cursor:'pointer'}} onClick={() => setOpenConsulationHistory(false)} />
                                            </div>
                                            <div style={{ overflow: 'auto', height: 550 }}>
                                            <Table style={{margin: 0}}>
                                                <TableHead style={{position:'sticky', top:-1, zIndex: 9999, background: '#fff'}}>
                                                    <TableRow>
                                                        <TableCell align='center'>Expert Name</TableCell>
                                                        <TableCell align='center'>Expert Role</TableCell>
                                                        <TableCell align='center'>Date</TableCell>
                                                        <TableCell align='center'>Slot</TableCell>
                                                        <TableCell align='center'>Status</TableCell>
                                                        <TableCell align='center'>Action By</TableCell>
                                                        <TableCell align='center'>Join Date</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {ConsulationHistory.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{textAlign:'center'}}>{item.expert_name}</TableCell>
                                                        <TableCell style={{textAlign:'center'}}>{item.expert_role}</TableCell>
                                                        <TableCell style={{textAlign:'center'}}>{formatDate(item.date)}</TableCell>
                                                        <TableCell style={{textAlign:'center'}}>{item.slot}</TableCell>
                                                        <TableCell style={{textAlign:'center'}}>{item.status}</TableCell>
                                                        <TableCell style={{textAlign:'center'}}>{item.action_by}</TableCell>
                                                        <TableCell style={{textAlign:'center'}}>{item.join_date}</TableCell>
                                                    </TableRow>
                                                    ))}
                                                </TableBody> 
                                            </Table>
                                            </div>
                                        </Box>
                                    </Modal>

                                    {userObj?.counselling?.doctor.length !== 0 &&
                                        <div>
                                            <h3>Doctor</h3>
                                            <div style={{ marginBottom: "10px" }} />
                                            {userObj?.counselling?.doctor?.map((doctor, index) => (
                                                <>
                                                <div className="d-flex flex-wrap gap-40">
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='name' className="form-label">Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="name"
                                                            name='name'
                                                            disabled={!data.available}
                                                            placeholder='Name'
                                                            value={doctor.name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='role_name' className="form-label">Role Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="role_name"
                                                            name='role_name'
                                                            disabled={!data.available}
                                                            placeholder='Role Name'
                                                            value={doctor.role_name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='time' className="form-label">Time</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="time"
                                                            name='time'
                                                            disabled={!data.available}
                                                            placeholder='Time'
                                                            value={doctor.time}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='date' className="form-label">Date</FormLabel>
    
                                                        <TextField
                                                            type="text"
                                                            id="date"
                                                            name='date'
                                                            disabled={!data.available}
                                                            placeholder='Date'
                                                            value={dayjs(doctor.date).format("DD/MM/YYYY")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                </div>
                                                <br/>
                                                </>
                                            ))}
                                        </div>
                                    }



                                    {
                                        userObj?.counselling?.nutrigenetic_expert.length !== 0 &&
                                        <div>
                                            <h3>Nutrigenetic Expert</h3>
                                            <div style={{ marginBottom: "10px" }} />
                                            {userObj?.counselling?.nutrigenetic_expert?.map((doctor, index) => (
                                                <>
                                                <div className="d-flex flex-wrap gap-40">
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='name' className="form-label">Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="name"
                                                            name='name'
                                                            disabled={!data.available}
                                                            placeholder='Name'
                                                            value={doctor.name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='role_name' className="form-label">Role Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="role_name"
                                                            name='role_name'
                                                            disabled={!data.available}
                                                            placeholder='Role Name'
                                                            value={doctor.role_name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='time' className="form-label">Time</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="time"
                                                            name='time'
                                                            disabled={!data.available}
                                                            placeholder='Time'
                                                            value={doctor.time}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='date' className="form-label">Date</FormLabel>
    
                                                        <TextField
                                                            type="text"
                                                            id="date"
                                                            name='date'
                                                            disabled={!data.available}
                                                            placeholder='Date'
                                                            value={dayjs(doctor.date).format("DD/MM/YYYY")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                </div>
                                                <br/>
                                                </>
                                            ))}
                                        </div>
                                    }



                                    {
                                        userObj?.counselling?.nutritionist.length !== 0 &&
                                        <div>
                                            <h3>Nutritionist</h3>
                                            <div style={{ marginBottom: "10px" }} />
                                            {userObj?.counselling?.nutritionist?.map((doctor, index) => (
                                                <>
                                                <div className="d-flex flex-wrap gap-40">
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='name' className="form-label">Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="name"
                                                            name='name'
                                                            disabled={!data.available}
                                                            placeholder='Name'
                                                            value={doctor.name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='role_name' className="form-label">Role Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="role_name"
                                                            name='role_name'
                                                            disabled={!data.available}
                                                            placeholder='Role Name'
                                                            value={doctor.role_name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='time' className="form-label">Time</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="time"
                                                            name='time'
                                                            disabled={!data.available}
                                                            placeholder='Time'
                                                            value={doctor.time}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='date' className="form-label">Date</FormLabel>
    
                                                        <TextField
                                                            type="text"
                                                            id="date"
                                                            name='date'
                                                            disabled={!data.available}
                                                            placeholder='Date'
                                                            value={dayjs(doctor.date).format("DD/MM/YYYY")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                </div>
                                                <br/>
                                                </>
                                            ))}
                                        </div>
                                    }




                                    {
                                        userObj?.counselling?.emo_expert?.length !== 0 &&
                                        <div>
                                            <h3>Emotional Wellbeing Expert</h3>
                                            <div style={{ marginBottom: "10px" }} />
                                            {userObj?.counselling?.emo_expert?.map((doctor, index) => (
                                                <>
                                                <div className="d-flex flex-wrap gap-40">
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='name' className="form-label">Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="name"
                                                            name='name'
                                                            disabled={!data.available}
                                                            placeholder='Name'
                                                            value={doctor.name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='role_name' className="form-label">Role Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="role_name"
                                                            name='role_name'
                                                            disabled={!data.available}
                                                            placeholder='Role Name'
                                                            value={doctor.role_name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='time' className="form-label">Time</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="time"
                                                            name='time'
                                                            disabled={!data.available}
                                                            placeholder='Time'
                                                            value={doctor.time}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='date' className="form-label">Date</FormLabel>
    
                                                        <TextField
                                                            type="text"
                                                            id="date"
                                                            name='date'
                                                            disabled={!data.available}
                                                            placeholder='Date'
                                                            value={dayjs(doctor.date).format("DD/MM/YYYY")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                </div>
                                                <br/>
                                                </>
                                            ))}
                                        </div>
                                    }



                                    {
                                        userObj?.counselling?.psychiatrist_expert?.length !== 0 &&
                                        <div>
                                            <h3>Psychiatrist</h3>
                                            <div style={{ marginBottom: "10px" }} />
                                            {userObj?.counselling?.psychiatrist_expert?.map((doctor, index) => (
                                                <>
                                                <div className="d-flex flex-wrap gap-40">
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='name' className="form-label">Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="name"
                                                            name='name'
                                                            disabled={!data.available}
                                                            placeholder='Name'
                                                            value={doctor.name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='role_name' className="form-label">Role Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="role_name"
                                                            name='role_name'
                                                            disabled={!data.available}
                                                            placeholder='Role Name'
                                                            value={doctor.role_name}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='time' className="form-label">Time</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            id="time"
                                                            name='time'
                                                            disabled={!data.available}
                                                            placeholder='Time'
                                                            value={doctor.time}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
                                                    <div className="text-field">
                                                        <FormLabel htmlFor='date' className="form-label">Date</FormLabel>
    
                                                        <TextField
                                                            type="text"
                                                            id="date"
                                                            name='date'
                                                            disabled={!data.available}
                                                            placeholder='Date'
                                                            value={dayjs(doctor.date).format("DD/MM/YYYY")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    "& .MuiOutlinedInput-input": {
                                                                        padding: "15px"
                                                                    },
                                                                }
                                                            }} />
                                                    </div>
    
                                                </div>
                                                <br/>
                                                </>
                                            ))}
                                        </div>
                                    }
                                </>
                        }


                        <h3>Other Status</h3>
                        <hr style={{ marginBottom: "24px" }} />

                        <div className="d-flex flex-wrap gap-40">
                            <div className="text-field">
                                <FormLabel htmlFor='blood_report' className="form-label">Blood Report</FormLabel>
                                <TextField
                                    type="text"
                                    id="barcode"
                                    name='invoice_id'
                                    disabled={!data.available}
                                    placeholder='Invoice Id'
                                    value={otherStatus.blood_report_status}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                            <div className="text-field">
                                <FormLabel htmlFor='invoice_id' className="form-label">Invoice Id</FormLabel>

                                <TextField
                                    type="text"
                                    id="barcode"
                                    name='invoice_id'
                                    disabled={!data.available}
                                    placeholder='Invoice Id'
                                    value={otherStatus.invoice_id}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                            <div className="text-field">
                                <FormLabel htmlFor='naf' className="form-label">NAF</FormLabel>
                                <TextField
                                    type="text"
                                    id="barcode"
                                    name='invoice_id'
                                    disabled={!data.available}
                                    placeholder='Invoice Id'
                                    value={otherStatus.naf}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                            {/* <div className="text-field">
                                <FormLabel htmlFor='doctor_counselling' className="form-label">Doctor Counselling</FormLabel>

                                <TextField
                                    type="text"
                                    id="barcode"
                                    name='invoice_id'
                                    disabled={!data.available}
                                    placeholder='Invoice Id'
                                    value={otherStatus.doctor_counselling}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div> */}
                            <div className="text-field">
                                <FormLabel htmlFor='diet_plan' className="form-label">Diet Plan</FormLabel>
                                <TextField
                                    type="text"
                                    id="barcode"
                                    name='invoice_id'
                                    disabled={!data.available}
                                    placeholder='Invoice Id'
                                    value={otherStatus.diet_plan}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap gap-40 mt-20">
                            {/* <div className="text-field">
                                <FormLabel htmlFor='nutritionist_counselling' className="form-label">Nutritionist Counselling</FormLabel>
                                <TextField
                                    type="text"
                                    id="barcode"
                                    name='invoice_id'
                                    disabled={!data.available}
                                    placeholder='Invoice Id'
                                    value={otherStatus.nutritionist_counselling}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div> */}

                            {/* <div className="text-field">
                                <FormLabel htmlFor='doctor_prescription' className="form-label">Doctor Prescription</FormLabel>

                                <TextField
                                    type="text"
                                    id="barcode"
                                    name='invoice_id'
                                    disabled={!data.available}
                                    placeholder='Invoice Id'
                                    value={otherStatus.doctor_prescription}
                                    variant="outlined"
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "15px"
                                            },
                                        }
                                    }} />
                            </div> */}
                        </div>
                    </MainCard>
                </MainCard>
            </div>
        </>
    );
};

export default UpdateCustomerField;